.back-button {
  position: fixed;
  top: 20px;
  left: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: lightgrey;
  transition: opacity 0.3s ease, color 0.3s ease, transform 0.3s ease;
  z-index: 1000;
}

.back-button:hover {
  transform: scale(1.1);
  opacity: 1 !important;
  color: white;
  text-shadow: 0 0 10px white;
  background-color: 0 0 10px white;
}